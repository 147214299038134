import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import leaf from "../../Assets/Projects/cpp.png";
import emotion from "../../Assets/Projects/wheelchair.png";
import editor from "../../Assets/Projects/codeEditor.png";
import chatify from "../../Assets/Projects/FindLegal.jpg";
import suicide from "../../Assets/Projects/vote.png";
import bitsOfCode from "../../Assets/Projects/CarPool.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={chatify}
              isBlog={false}
              title="FindLegal"
              description="An E-MarketPlace for onboarding Legal Service Providers(LSP's) in India where Client and an LSP can get in touch conveniently and hassle-free."
              ghLink="https://github.com/yashlahoti24/FindLegal"
              demoLink="#"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={bitsOfCode}
              isBlog={false}
              title="P2PCar"
              description="A decentralized approach to traditional car pooling system where driver and rider can directly contact and can decide a cost mutually by bargaining."
              ghLink="#"
              demoLink="#"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={editor}
              isBlog={false}
              title="Editor.io"
              description="Online code and markdown editor build with react.js. Online Editor which supports html, css, and js code with instant view of website. Online markdown editor for building README file which supports GFM, Custom Html tags with toolbar and instant preview.Both the editor supports auto save of work using Local Storage"
              ghLink="#"
              demoLink="#"              
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={leaf}
              isBlog={false}
              title="Mini CPP Compiler"
              description="A mini CPP compiler built using LEX and YAAC which can compile basic C++ program using if-else statements and loops. Python is used for code optimization."
              ghLink="https://github.com/yashlahoti24/mini-cpp-compiler"
              demoLink="#"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={suicide}
              isBlog={false}
              title="Online Voting System"
              description="Voting made Online.., A safe and secure centralized system for voting built using HTML, NodeJS and MySQL where users after succesful registration and approval by admin can cast their vote."
              ghLink="#"
              demoLink="#"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={emotion}
              isBlog={false}
              title="FreeMove"
              description="A smart wheelchair built using ESP8266 and Ardiuno IDE which features controls like Joystick, Gyroscope and Voice Commands. It also supports obstacke detection feature so user can operate it without any risk of accidents, which makes the life of disabled people even better."
              ghLink="#"
              demoLink="https://drive.google.com/drive/u/0/folders/1JsN_elzxJ2yfIr43pXecq4Uh6IJ3Vm4x?q=parent:1JsN_elzxJ2yfIr43pXecq4Uh6IJ3Vm4x"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
